import { useState, useEffect, useCallback } from 'react';

export const useLoader = (action: () => void, handleLoader: (loadingState: boolean) => void) => {
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (isPending) {
      action();

      handleLoader(false);
      setIsPending(false);
    }
  }, [isPending]);

  const startLoader = useCallback(() => {
    handleLoader(true);
    setIsPending(true);
  }, [handleLoader]);

  return startLoader;
};
