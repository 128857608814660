import { ExtendedStudioModel, StudioModel, StudioModelFiltered } from '../../models/StudioModel';
import { StudioFilter, InputInformation, InputFilters } from './interfaces';
import { GeolibInputCoordinates } from 'geolib/es/types';
import { getDistance } from 'geolib';
import { CheckInOption } from '../../models/CheckIn';
import { initialState } from './initialState';
import { Category } from '../../models/Category';
import { UserMemberships } from '../../models/user_membership';
// https://github.com/zauberware/postal-codes-json-xml-csv/blob/master/data/DE.zip
import OrteUndBundeslaender from '../../data/zipcodes.de.json';
import extendedStudiosDe from '../../data/partners/de/partners_extended_services.json';
import extendedStudiosEn from '../../data/partners/en/partners_extended_services.json';
import { getLanguage } from '../../utils/language.utils';
import cities from '../../data/partners/postal-code_to_city.json';
import metaData from '../../data/partners/postal-code_and_population.json';
import slugify from 'slugify';
import { maxBy } from 'lodash';

export const checkinImageBasePath = 'images/checkin_logos/';

export interface City {
  osm_id: number;
  ags: string | number;
  ort: string;
  plz: string | number;
  landkreis: string;
  bundesland: string;
}

export interface ZipCodeMetaData {
  plz: number;
  note: string;
  einwohner: number;
  qkm: number;
  lat: number;
  lon: number;
}

export const getDistanceInKilometers = (
  firstPoint: GeolibInputCoordinates,
  secondPoint: GeolibInputCoordinates,
): number => parseFloat((getDistance(firstPoint, secondPoint) / 1000).toFixed(2));

export const isStudioValid = (studio: StudioModel) =>
  !!studio.publicId &&
  !!studio.coordLat &&
  !!studio.coordLong &&
  studio.studioVisibility &&
  studio.published &&
  'servicePackages' in studio;

const OrteUndBundeslaenderSet = new Set(
  OrteUndBundeslaender.map(orte => `${orte.zipcode}:${orte.state.toLowerCase()}`),
);

const membershipBasic = UserMemberships.basic.toString();
const membershipPro = UserMemberships.pro.toString();
const membershipBest = UserMemberships.best.toString();

export const getFilteredStudios = ({
  studios,
  filter,
  visibleStudioInputInformation,
  radius,
}: {
  studios: StudioModelFiltered[];
  filter: StudioFilter;
  radius: number;
  visibleStudioInputInformation?: InputInformation;
}): StudioModelFiltered[] => {
  const language = getLanguage();
  const extendedStudiosData = (
    language === 'de' ? extendedStudiosDe : extendedStudiosEn
  ) as ExtendedStudioModel;
  const { checkInApp, servicePackages, categories } = filter;
  const maxDistance = radius * 1000;

  const formattedServicePackages = servicePackages.map(service => service.toString());
  const servicePackagesWithoutMemberships = formattedServicePackages.filter(
    servicePackage =>
      servicePackage !== membershipBasic &&
      servicePackage !== membershipPro &&
      servicePackage !== membershipBest,
  );
  const visibleStudioInputInformationZipSearchCleaned =
    visibleStudioInputInformation?.name?.toString()?.replace(', Deutschland', '') || '';

  const isBasic = formattedServicePackages.includes(membershipBasic);
  const isPro = formattedServicePackages.includes(membershipPro);
  const isBest = formattedServicePackages.includes(membershipBest);

  const isFilteringByPackage = isBasic || isPro || isBest;
  const hasFederalStateFilter = visibleStudioInputInformation?.predictionTypes?.includes(
    'administrative_area_level_1',
  );

  let studiosReducedByServicePackage = studios;

  if (isFilteringByPackage) {
    studiosReducedByServicePackage = studiosReducedByServicePackage.filter(studio => {
      const hasBasic = Object.keys(studio.servicePackages).includes(membershipBasic);
      const hasPro = Object.keys(studio.servicePackages).includes(membershipPro);
      const hasBest = Object.keys(studio.servicePackages).includes(membershipBest);

      return (
        (isBasic && hasBasic) ||
        (isPro && (hasPro || hasBasic)) ||
        (isBest && (hasBasic || hasPro || hasBest))
      );
    });
  }

  if (filter.equipmentAndServices.length > 0) {
    studiosReducedByServicePackage = studiosReducedByServicePackage.filter(studio =>
      filter.equipmentAndServices.every(
        equipment =>
          extendedStudiosData?.[studio.publicId]?.fitnessServices?.includes(equipment) ||
          extendedStudiosData?.[studio.publicId]?.wellnessServices?.includes(equipment) ||
          extendedStudiosData?.[studio.publicId]?.services?.includes(equipment),
      ),
    );
  }
  return studiosReducedByServicePackage.filter(studio => {
    const distance = visibleStudioInputInformation
      ? getDistance(
          { lat: visibleStudioInputInformation.lat, lng: visibleStudioInputInformation.lng },
          {
            latitude: studio.coordLat,
            longitude: studio.coordLong,
          },
        )
      : undefined;

    const inFederalState = OrteUndBundeslaenderSet.has(
      `${studio.zip.trim()}:${visibleStudioInputInformationZipSearchCleaned.toLowerCase()}`,
    );

    const isInRange =
      !distance || distance < maxDistance || (hasFederalStateFilter && inFederalState);
    const checkCheckInApp = (checkInApp && studio.checkInApp) || !checkInApp;

    const matchCategoryPrimary =
      (categories.length && categories.includes(studio.categoryPrimary)) ||
      (categories.length === 0 && studio.categoryPrimary) ||
      (categories.length === 0 && !studio.categoryPrimary);

    const matchCategories = categories.some(element => studio.categories.includes(element));

    const hasServicePackages = servicePackagesWithoutMemberships.length > 0;
    const matchServicePackages =
      !hasServicePackages ||
      Object.keys(studio.servicePackages).some(service =>
        servicePackagesWithoutMemberships.includes(service),
      );

    return (
      isInRange &&
      checkCheckInApp &&
      (matchCategoryPrimary || matchCategories) &&
      matchServicePackages
    );
  });
};

export const getFilterToStateFilter = ({
  checkInsAndServices,
  categories,
  memberships,
  equipmentAndServices,
}: InputFilters): StudioFilter => {
  const servicesPackages = [];

  const filterState: StudioFilter = {
    ...initialState.filter,
  };

  filterState.checkInApp = checkInsAndServices.indexOf(CheckInOption.App) !== -1;

  if (checkInsAndServices.indexOf(CheckInOption.Twogether) !== -1) {
    servicesPackages.push(18);
  }

  filterState.categories = categories;

  filterState.servicePackages = [...servicesPackages, ...memberships];

  filterState.equipmentAndServices = equipmentAndServices;

  return filterState;
};

export const getCategoryById = (categories: Category[], id: number): Category => {
  const category = categories.find(category => category.id === id);

  return category
    ? category
    : {
        id: 0,
        shortDescription: null,
        iconUrlContour: '',
        iconUrlFilled: '',
        iconUrlCircle: '',
        iconUrlSelected: '',
        iconUrlWithoutCircle: '',
        imageUrl: '',
        mapMarkerUrlInactive: '',
        longDescription: '',
        mapMarkerUrlActive: '',
        mapMarkerUrlSelected: '',
      };
};

export const getResolvedCategories = (studio: StudioModel, categories: Category[]) => {
  const combinedCategories = [
    studio.categoryPrimary,
    ...(studio.categories ? studio.categories : []),
  ].map(combinedCategory => {
    const foundCategory = categories.find(category => category.id === combinedCategory);
    if (foundCategory) {
      return foundCategory.shortDescription;
    }
    return '';
  });

  return combinedCategories.join(', ');
};

export const getCityByZipCode = (zipCode: string | number, cities: City[]): City | undefined =>
  // eslint-disable-next-line eqeqeq
  cities.find(city => city.plz == zipCode);

export const getNormalizedString = (name: string | undefined) => {
  if (name) {
    return slugify(name, { lower: true });
  } else {
    return '';
  }
};

export const getCitiesByDistrict = (district: string, cities: City[]): City[] =>
  cities.filter(city => city.landkreis === district);

export const getCityWithHighestPopulation = (
  cities: City[],
  metaData: ZipCodeMetaData[],
): ZipCodeMetaData | undefined => {
  const selectedZipCodeMetaData: ZipCodeMetaData[] = [];

  cities.forEach(city => {
    const matchedMetaData = metaData.find(data => data.plz === city.plz);
    if (matchedMetaData) {
      selectedZipCodeMetaData.push(matchedMetaData);
    }
  });

  return maxBy(selectedZipCodeMetaData, 'einwohner');
};

export const getPartnerSitePath = ({
  language,
  city,
  partner,
  categories,
}: {
  language: string;
  city: City | undefined;
  categories: Category[];
  partner: StudioModel;
}) => {
  const category = categories.find(category => category.id === partner.categoryPrimary);

  return `/${language}/studio-finden${city?.ort ? '/' + getNormalizedString(city?.ort) : ''}${
    category ? '/' + getNormalizedString(category?.shortDescription as string) : ''
  }/${getNormalizedString(partner.studioName)}`;
};

export const getPartnerPath = (partner: StudioModel, language: string, categories: Category[]) => {
  let city = getCityByZipCode(partner.zip, cities);

  if (!city || !city.landkreis) {
    return getPartnerSitePath({ language, city, categories, partner });
  }

  const citiesByDistrict = getCitiesByDistrict(city.landkreis, cities);
  const cityWithHighestPopulation = getCityWithHighestPopulation(citiesByDistrict, metaData);

  const finalCity = cityWithHighestPopulation
    ? cities.find(city => city.plz === cityWithHighestPopulation.plz)
    : city;

  return getPartnerSitePath({
    language,
    city: finalCity,
    categories,
    partner,
  });
};
